import ph3 from "../../topResort/TopResortsHotel-3.webp"
import ph4 from "../../topResort/TopResortsHotel-4.webp"
import ph5 from "../../topResort/TopResortsHotel-5.webp"
import ph6 from "../../topResort/TopResortsHotel-6.webp"
import ph7 from "../../topResort/TopResortsHotel-7.webp"
import ph8 from "../../topResort/TopResortsHotel-8.webp"
import ph9 from "../../topResort/TopResortsHotel-9.webp"
import ph10 from "../../topResort/TopResortsHotel-10.webp"
import ph11 from "../../topResort/TopResortsHotel-11.webp"
import {Helmet} from "react-helmet";
const CardBest = () => {
    return (
        <section>
            <Helmet>
                <title>Top Resorts Hotel | Casino-hotellit | Australia</title>
                <meta name="description" content="Top Resorts Hotel invites you to a world of elegance and entertainment. Explore our exceptional casino hotels in Australia and New Zealand, enjoy first-class service and unique gaming opportunities." />
            </Helmet>
            <section id="banner_sec">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Top Resorts Hotel</h1>
                        <h2>Create memories while traveling!</h2>
                        <p>AWelcome to Top Resorts Hotel, where every journey begins with inspiration! Our carefully selected itineraries and recommendations will help you find the perfect place to relax. We strive to make your trip not only exciting, but also comfortable, providing all the necessary information and support at every stage.</p>
                        <p>On the Top Resorts Hotel website, you will find a rich selection of destinations, unique offers and practical tips that will help you plan the perfect vacation. Join us and begin your journey to new discoveries and emotions. Travel is not only about new places, but also new impressions that will stay with you forever.</p>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Top Hotels in Australia</h2>
                    <div className="eg-widget" data-widget="search" data-program="au-expedia" data-lobs="stays"
                         data-network="pz" data-camref="1101l3BKwY" data-pubref="Zokies"></div>
                    <div className="woh">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="text_box">

                                    <div className="main_hotel">
                                        <a href="https://www.hapukulodge.com/" target="_blank">
                                            <div className="img_box">
                                                <img src={ph3} alt=""/>
                                            </div>
                                        </a>
                                        <div className="text_hotel">
                                            <h3>Hapuku Lodge + Tree Houses</h3>
                                            <p>Hapuku Lodge & Tree Houses is located in Hapuku. Free WiFi, an outdoor pool and a restaurant are featured. All accommodations feature a 2-person hot tub, fireplace and balcony. The tree houses are located 9 metres above sea level.</p>
                                            <div className="more">
                                                <a href="https://www.hapukulodge.com/">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="text_box">

                                    <div className="main_hotel">
                                        <a href="https://therees.co.nz/" target="_blank">
                                            <div className="img_box">
                                                <img src={ph4} alt=""/>
                                            </div>
                                        </a>
                                        <div className="text_hotel">
                                            <h3>Hotel "Rice"</h3>
                                            <p>
                                                The Rice Hotel & Luxury Apartments is a great choice for travelers visiting Queenstown. It offers a good combination of price and quality, comfort and convenience, a romantic atmosphere and services designed to make your stay very enjoyable.
                                            </p>
                                            <div className="more">
                                                <a href="https://therees.co.nz/">Read More</a>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="text_box">

                                    <div className="main_hotel">
                                        <a href="https://www.brilliantbylangham.com/en"
                                           target="_blank">
                                            <div className="img_box">
                                                <img src={ph5} alt=""/>
                                            </div>
                                        </a>
                                        <div className="text_hotel">
                                            <h3>The Langham Auckland</h3>
                                            <p>Langham is a great choice for travellers visiting Auckland, offering a good combination of price and quality, comfort and convenience, a romantic atmosphere and services designed to make your stay very enjoyable.</p>
                                            <div className="more">
                                                <a href="https://www.brilliantbylangham.com/en">Read
                                                    More</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Top places to grab a bite</h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://depoteatery.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph6} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Depot Eatery & Oyster Bar</h3>
                                        <p>Depot Eatery & Oyster Bar in Auckland is a great place for foodies who value freshness and quality. It offers a unique atmosphere where you can enjoy exquisite seafood dishes and mouth-watering snacks prepared with the finest ingredients. Special attention is paid to oysters, which are served straight from the ice, as well as a variety of local and international wines, which perfectly complement each meal.</p>
                                        <div className="more">
                                            <a href="https://depoteatery.co.nz/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.fleursplace.com" target="_blank">
                                        <div className="img_box">
                                            <img src={ph7} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Flower's Place</h3>
                                        <p>Flower's Place Restaurant in Moeraki is a cosy establishment offering a unique dining experience in a picturesque setting. Here you can enjoy dishes prepared with fresh local ingredients, with an emphasis on seasonal vegetables and seafood. The interior of the restaurant combines stylish design and comfort, creating a warm and welcoming atmosphere for guests.</p>
                                        <div className="more">
                                            <a href="https://www.fleursplace.com">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://botswanabutchery.nz/queenstown/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph8} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Botswana Butcher Shop</h3>
                                        <p>The Botswana Butcher Shop in Queenstown is a gastronomic haven for lovers of great meat. The establishment is renowned for its wide range of excellent steaks and barbecues prepared with the finest meat products. The restaurant’s cosy atmosphere, with rustic touches and an open grill, creates the perfect setting for enjoying delicious food with friends and family.</p>
                                        <div className="more">
                                            <a href="https://botswanabutchery.nz/queenstown/">Read More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Interesting and beautiful places to visit</h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.waitomo.com/glowworms-and-caves/waitomo-glowworm-caves" target="_blank">
                                        <div className="img_box">
                                            <img src={ph9} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Waitomo Glowworm Caves</h3>
                                        <p>The Waitomo Glowworm Caves are a mesmerising underground wonder where nature reveals its most magical secrets. Inside these ancient caves you will find yourself under a mesmerising sky made up of glowworms, creating the effect of a starry night in the pitch black. Spectacular stalactites and stalagmites reflected in crystal clear underground rivers add drama and magic to this natural spectacle.</p>
                                        <div className="more">
                                            <a href="https://www.waitomo.com/glowworms-and-caves/waitomo-glowworm-caves">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.hobbitontours.com/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph10} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Hobbiton</h3>
                                        <p>Hobbiton is a magical place, as if it came straight out of the pages of Tolkien's books, where fairy-tale landscapes and cozy hobbit houses come to life before your eyes. This unique corner of New Zealand offers you an immersion into the world of Middle-earth, with its picturesque green hills, blooming gardens and characteristic year-round houses. Walking through Hobbiton, you can feel like a part of the legendary story, exploring the decorated interiors, admiring the beautiful views and immersing yourself in an atmosphere of real magic.</p>
                                        <div className="more">
                                            <a href="https://www.hobbitontours.com/">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.milford-sound.co.nz/"
                                       target="_blank">
                                        <div className="img_box">
                                            <img src={ph11} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Milford Sound</h3>
                                        <p>Milford Sound is a stunning corner of New Zealand that captivates with its pristine beauty and grandeur. Surrounded by steep cliffs covered in greenery and deep waters, this fjell creates scenes that seem almost unreal. Majestic waterfalls rushing down from above and misty clouds enveloping the mountain peaks add to the atmosphere of mystery and grandeur of this natural wonder. Milford Sound is the perfect place to enjoy the tranquility of being immersed in nature and experience a feeling of complete unity with the pristine wild beauty.</p>
                                        <div className="more">
                                            <a href="https://www.milford-sound.co.nz/">Read
                                                More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </section>

    )
}
export default CardBest