import ph19 from "../../topResort/TopResortsHotel-19.webp"
import ph20 from "../../topResort/TopResortsHotel-20.webp"
import ph21 from "../../topResort/TopResortsHotel-21.webp"


import { Helmet } from 'react-helmet';
const Casino = () => {
    return (
        <div>
            <Helmet>
                <title>Top Resorts Hotel: Exquisite Casinos in Australia and New Zealand</title>
                <meta name="description" content="Discover the best casino hotels in Australia and New Zealand with Top Resorts Hotel. Enjoy a variety of games, luxurious interiors and impeccable service that will make your stay unforgettable." />
            </Helmet>
            <section id="Casino_Hotels">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Top hotel</h1>
                        <h2>Travel and fill your life with meaning!</h2>
                        <p>Here, among the sparkling lights and bright gaming halls, you will not only find exciting games and gambling entertainment, but also first-class service, exquisite cuisine and unrivaled comfort. Australian casinos are a true oasis for connoisseurs of entertainment, offering a wide range of games from classic slots to complex poker strategies.</p>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Best Casino Hotels</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.christchurchcasino.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph19} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Christchurch Casino</h3>
                                        <p>Christchurch Casino is New Zealand's first and one of the most prestigious casinos, located in the heart of Christchurch. Renowned for its elegant atmosphere and wide range of gaming options, the casino attracts guests looking for both gaming and first-class service. Inside, you'll find stylish gaming rooms, a variety of table games and slots, as well as excellent restaurants and bars to relax and enjoy gastronomic delights.</p>
                                        <div className="more">
                                            <a href="https://www.christchurchcasino.co.nz/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://skycityauckland.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph20} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>SkyCity Auckland</h3>
                                        <p>The 24-hour SkyCity Casino offers live entertainment, a theatre and a choice of over 20 restaurants, cafes and bars. The Sky Tower, rising 328 metres above sea level, offers breathtaking 360-degree views from three observation decks. Union Pay is accepted.</p>
                                        <div className="more">
                                            <a href="https://skycityauckland.co.nz/">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://grandcasino.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph21} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Dunedin Casino</h3>
                                        <p>The Grand Casino offers entertainment for everyone, with world-class gaming tables and first-class cuisine. The Grand Casino is located next to the Scenic Hotel Southern Cross, a Heritage 1 building in the centre of Dunedin.</p>
                                        <div className="more">
                                            <a href="https://grandcasino.co.nz/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="content_new">
                        <p>Whether you're looking for a luxurious gaming experience or a more casual night out, there is
                            something for everyone
                            to enjoy in Australia's casinos. Entry to the casinos is strictly 20+, and suitable dress
                            attire must be worn. Never
                            gamble more than you can afford to lose. Gambling can be addictive and should only be played
                            as
                            entertainment.</p>
                    </div>
                </div>
            </section>
        </div>

    )
}
export default Casino
