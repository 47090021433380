import {Helmet} from "react-helmet";
import close_modal from "../../images/close.png";
import {useEffect} from "react";

const Contact = () =>{


    useEffect(() => {
        let modal = document.getElementsByClassName("modal_overlay")[0];
        let form_button = document.getElementById("sub");
        let close_modal = document.getElementsByClassName("close_modal_button")[0];
        form_button.addEventListener("click",function(e){
            e.preventDefault();
            setTimeout(() => {
                modal.style.display = "block";
            },300)
        });
        close_modal.addEventListener("click",function(e){
            modal.style.display = "none";
        })


    },[])

    return (
        <div>
            <Helmet>
                <title>Top Resorts Hotel: Hotel Reservation Contact Information</title>
                <meta name="description" content="Looking for information on Top Resorts Hotel casino resorts in Australia and New Zealand? Visit our Contacts section for all the information you need and assistance from our support team." />
            </Helmet>
            <section id="Contact">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Contacts</h1>
                        <h2>Let your dreams come true!</h2>
                    </div>
                </div>
            </section>
            <div className="content_page_text">
                <div className="container">
                    <div className="page_text">
                        <p>The purpose of the Top Resorts Hotel portal is to provide users with unique and up-to-date information about the best places for recreation and entertainment, helping them choose the ideal options for unforgettable travel and comfortable stay</p>
                    </div>
                </div>
            </div>
            <div className="contact_us">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="contact_us_f">
                                <h2>Contact Form</h2>
                                <form action="#">
                                    <div className="form_div">
                                        <input type="text" name="user" id="user" placeholder="Your Name"
                                               autoComplete="off"/>

                                    </div>
                                    <div className="form_div ">
                                        <input type="text" name="Your Email" id="Your Email" placeholder="Your Email"
                                               autoComplete="off"/>
                                        <input type="text" name="Subj" id="Subj" placeholder="Subject"
                                               autoComplete="off"/>
                                    </div>
                                    <div className="text_a">
                                        <textarea id="w3review" name="w3review" rows="4" cols="50"
                                                  placeholder=" Message"></textarea>
                                    </div>
                                    <input  type="submit" name="" id="sub" value="submit"/>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="touch">
                                <h4>Get in touch with us</h4>
                                <div className="mail">
                                    <ul>

                                        <li><a href="tel:+61 28 774 3341"><i className="fa fa-phone" aria-hidden="true"></i>+61
                                            28 774 3341</a></li>
                                        <li><a href="mailto:info@topresortshotel.com"><i className="fa fa-envelope-o" aria-hidden="true"></i>info@topresortshotel.com</a></li>
                                        <li>753 Hume Hwy, Bass Hill NSW 2197, Australia</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal_overlay">
                <div className="modal_inner">
                    <div className="close_modal_button">
                        <img src={close_modal} alt="close"/>
                    </div>
                    Thank you for Application! <br/>
                    We will contact as soon as we can
                </div>
            </div>
        </div>
    )
}

export default Contact