import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer_logo">

                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="Useful_link">
                            <h2>Useful Links</h2>
                            <ul>
                                <li>
                                    <NavLink to='/'>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/luxury'>Luxury</NavLink>
                                </li>
                                <li><NavLink to='/casino'>Casino</NavLink></li>
                                <li><NavLink to='/budget'>Budget</NavLink></li>
                                <li><NavLink to='/boutique'>Boutique</NavLink></li>
                                <li><NavLink to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="get">
                            <h2>Company address </h2>
                            <div className="location">
                                <ul>
                                    <li><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i>444 S. Cedros
                                        Ave
                                        Solana Beach, California</a></li>
                                    <li><a href="#"><i className="fa fa-phone" aria-hidden="true"></i> +1.423.5215.1165</a>
                                    </li>
                                    <li><a href="#"><i className="fa fa-envelope-o" aria-hidden="true"></i>support@topresort4u.com</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="copyrights">
                                <p>Copyright © 2023-2024 topresort4u.com</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="policey">
                                <ul>
                                    <li>
                                        <NavLink to='/terms'>Terms and Conditions</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to='/privacy'>Privacy Policy</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer