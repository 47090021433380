import {Helmet} from "react-helmet";
import ph23 from "../../topResort/TopResortsHotel-23.webp"
import ph24 from "../../topResort/TopResortsHotel-24.webp"
import ph25 from "../../topResort/TopResortsHotel-25.webp"
import ph26 from "../../topResort/TopResortsHotel-26.webp"

const Budget = () => {
    return (
        <div>
            <Helmet>
                <title>Top Resorts Hotel: Budget Hotels in Australia and New Zealand</title>
                <meta name="description" content="Top Resorts Hotel offers budget casino hotels in Australia and New Zealand. Enjoy exciting games and comfortable accommodation at reasonable prices, providing excellent service quality for your budget." />
            </Helmet>
            <section id="Budget_Friendly">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Budget</h1>
                        <h2>Go where your heart leads!</h2>
                        <p>Australia offers not only luxurious resorts, but also a variety of budget holiday options that allow you to enjoy the beauty and culture of the country without breaking the bank. From cozy hostels in the city centres to oceanfront campsites and affordable apartments with stunning views, there is something for everyone.</p>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Best Budget Hotels</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.jucysnooze.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph23} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>JUCY Snooze</h3>
                                        <p>LyLo Queenstown is within walking distance of restaurants, shops and Queenstown's vibrant nightlife. For adrenaline junkies, the hotel is just 200 metres from the Skyline Gondola and Luge. A range of rooms are available, including pods, which consist of a single bed in a shared room, and hotel rooms with en suite bathrooms.</p>
                                        <div className="more">
                                            <a href="https://www.jucysnooze.co.nz/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.yha.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph24} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>YHA Australia</h3>
                                        <p>The award-winning YHA Sydney Harbour offers stunning rooftop views of the Sydney Opera House, Sydney Harbour Bridge and Sydney Harbour. The hostel is within a 10-minute walk of Wynyard Train Station and Bridge St Light Rail Station. It offers private and shared rooms, a TV lounge, pool tables and a fully equipped communal kitchen.</p>
                                        <div className="more">
                                            <a href="https://www.yha.co.nz/">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://kiwihotel.co.nz/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph25} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Kiwi International Hotel</h3>
                                        <p>TOffering quality affordable accommodation in the heart of Auckland city centre, Kiwi International is centrally located on the famous Queen Street, close to all the main restaurants, bars, transport links and entertainment options. The theatres and nightlife along K Street are just 100 metres away. SkyCity and Skytower are a 15-minute walk away. Viaduct and Spark Arena are a 5-minute drive away.</p>
                                        <div className="more">
                                            <a href="https://kiwihotel.co.nz/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="text_box">

                                        <div className="main_hotel">
                                            <a href="https://nomadsworld.com/" target="_blank">
                                                <div className="img_box">
                                                    <img src={ph26} alt=""/>
                                                </div>
                                            </a>
                                            <div className="text_hotel">
                                                <h3>Nomads Hostels</h3>
                                                <p>Nomad Hostel is a hostel in Nimrod, 16 km from Baniyas Waterfall. It features a garden, a shared lounge and views of the mountains. This non-smoking property is 9 km from Nimrod Fortress.Nomad Hostel is a hostel in Nimrod, 16 km from Baniyas Waterfall. It features a garden, a shared lounge and views of the mountains. This non-smoking property is 9 km from Nimrod Fortress.</p>
                                                <div className="more">
                                                    <a href="https://nomadsworld.com/">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="content_new">
                        <p>Australia offers a range of budget-friendly accommodation options for travelers on a budget.
                            Whether you're
                            looking for a hostel or a budget hotel, there is something for everyone to enjoy in New
                            Zealand's cheap
                            accommodation options.</p>
                    </div>
                </div>
            </section>
        </div>

    )
}
export default Budget
