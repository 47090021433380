import {NavLink} from "react-router-dom";
import logo from "../../topResort/Logo.png"
import {useEffect} from "react";

const Header = () => {


    useEffect(() => {
        const menuRef = document.getElementById("mySidenavs");
        const open_menu_block = document.getElementsByClassName("toggle_menu")[0];
        const close_menu_btn = document.getElementsByClassName("closebtn")[0];
        let menu_links = document.querySelectorAll(".menu_navigation a");


        if(window.screen.width < 900){
            open_menu_block.addEventListener("click",function(){
                menuRef.style.width = "100%";
            });
            close_menu_btn.addEventListener("click",function(){
                menuRef.style.width = "0px";
            });
            menu_links.forEach((link) => {
                link.addEventListener("click",function(){
                    menuRef.style.width = "0px";
                });
            })
        }


    },[])



    return (

        <header id="myHeader" className="header_section fixed-top">
            <div className="mid_menu_header">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="header_logo">
                                <NavLink to='/'><img src={logo} alt="logo"/></NavLink>
                            </div>
                        </div>

                        <div className="col-9 text-left menus"><a href="#"></a>
                            <div className="header_navigation"><a href="#"></a>
                                <div id="mySidenavs" className="sidenavs">
                                   <a className="closebtn">×</a>
                                    <ul className="menu_navigation">
                                        <li className="">
                                            <NavLink to='/'>Home</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/luxury'>Luxury</NavLink>
                                        </li>
                                        <li><NavLink to='/casino'>Casino</NavLink></li>
                                        <li><NavLink to='/budget'>Budget</NavLink></li>
                                        <li><NavLink to='/boutique'>Boutique</NavLink></li>
                                        <li><NavLink to='/contact'>Contact</NavLink></li>

                                    </ul>

                                </div>
                                <span className="toggle_menu"><i className="fa fa-bars"></i></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header