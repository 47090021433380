import ph28 from "../../topResort/TopResortsHotel-28.webp"
import ph29 from "../../topResort/TopResortsHotel-29.webp"
import ph30 from "../../topResort/TopResortsHotel-30.webp"

import {Helmet} from "react-helmet";

const Boutique = () => {

    return (
        <div>
            <Helmet>
                <title>Top Resorts Hotel: Boutique Casino Hotels in Australia and New Zealand</title>
                <meta name="description" content="Top Resorts Hotel offers exclusive boutique casino hotels in Australia and New Zealand. Enjoy personalized service and a unique atmosphere in our stylish and elegant casino hotels." />
            </Helmet>
            <section id="boutique">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Boutique</h1>
                        <h2>Choose your option from the best!</h2>
                        <p>Boutique hotels at Top Resorts Hotel are oases of uniqueness and sophistication, offering guests more than just a comfortable stay. Each establishment is distinguished by its own unique style, attention to detail and personalized service.</p>
                        <p>Staying at a boutique hotel will immerse you in an atmosphere of coziness and luxury, where each room has its own history and character, reflecting the local traditions and culture.</p>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Best Boutique Hotels</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://thespirehotel.com/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph28} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>The Spire Hotel, Queenstown</h3>
                                        <p>The Spire Hotel is a stylish boutique hotel located in the heart of Queenstown. Just a 2-minute walk from the shores of Lake Wakatipu, it offers rooms with free WiFi, a balcony and a gas fireplace. The Spire Hotel is a 10-minute walk from the Skyline Gondola and a 5-minute walk from Queenstown Gardens. Millbrook Golf Course and Coronet Peak Ski Areas are a 20-minute drive away. Queenstown Airport is a 15-minute drive away.
                                        </p>
                                        <div className="more">
                                            <a href="https://thespirehotel.com/">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.thegeorge.com" target="_blank">
                                        <div className="img_box">
                                            <img src={ph29} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>The George, Christchurch</h3>
                                        <p>The St. George is within easy reach of the city centre and its arts, restaurants and theatres. The Christchurch Botanic Gardens are also on your doorstep. Christchurch Casino is just a 3-minute walk away.</p>
                                        <div className="more">
                                            <a href="https://www.thegeorge.com">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://molliesboutique.hotelsauckland.net/en/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph30} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Mollies, Auckland</h3>
                                        <p>Mollies Auckland is a luxury boutique hotel that captivates with its elegant charm and attention to detail. Situated in a picturesque area, this hotel offers a unique combination of historical architecture and modern comfort. Guests can enjoy spacious rooms, exquisite cuisine and impeccable service, creating an atmosphere of absolute coziness and privacy.</p>
                                        <div className="more">
                                            <a href="https://molliesboutique.hotelsauckland.net/en/">Read
                                                More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="content_new">
                        <p>Whether you're looking for a luxurious escape in a restored Victorian villa or a modern and
                            sleek
                            hotel in the heart of
                            the city, there is something for everyone to enjoy in Australia's boutique hotels.</p>
                    </div>
                </div>
            </section>
        </div>

    )
}
export default Boutique
