import ph13 from "../../topResort/TopResortsHotel-13.webp"
import ph14 from "../../topResort/TopResortsHotel-14.webp"
import ph15 from "../../topResort/TopResortsHotel-15.webp"
import ph16 from "../../topResort/TopResortsHotel-16.webp"
import ph17 from "../../topResort/TopResortsHotel-17.webp"
import {Helmet} from "react-helmet";

const Luxury = () => {
    return (
        <div>
            <Helmet>
                <title>Top Resorts Hotel: Boutique Casino Hotels in Australia and New Zealand</title>
                <meta name="description" content="Top Resorts Hotel offers exclusive boutique casino hotels in Australia and New Zealand. Enjoy personalized service and a unique atmosphere in our stylish and elegant casino hotels." />
            </Helmet>
            <section id="banner">
                <div className="container">
                    <div className="banner_uppr_txt">
                        <h1>Luxury</h1>
                        <h2>Travel to find yourself!</h2>
                        <p>The establishments offer not only prestigious rooms and exquisite restaurants, but also exciting gaming rooms, where every visit turns into an unforgettable event. Enjoy first-class service, outstanding views of stunning landscapes and exclusive services that make every moment of your stay special. Discover casino hotels where style and comfort meet excitement and adventure, creating the perfect setting for a luxurious holiday and exciting games.</p>
                    </div>
                </div>
            </section>
            <section className="cardone">
                <div className="container">
                    <h2>Top Popular Casino Hotels in Australia</h2>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="text_box">
                                <div className="main_hotel">
                                    <a href="https://www.robertsonlodges.com/the-lodges/kauri-cliffs" target="_blank">
                                        <div className="img_box">
                                            <img src={ph13} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>The Lodge at Kauri Cliffs</h3>
                                        <p>Situated on 6,000 acres near Matauri Bay, the luxurious Rosewood Kauri Cliffs offers 180-degree ocean views, 3 private beaches, an 18-hole golf course and 2 tennis courts. Guests can also enjoy an infinity pool with a spa pool, a fitness centre, a day spa and an on-site restaurant.</p>
                                        <div className="more">
                                            <a href="https://www.robertsonlodges.com/the-lodges/kauri-cliffs">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">
                                <div className="main_hotel">
                                    <a href="https://hukalodge.com/" target="_blank">
                                        <div className="img_box">
                                            <img src={ph14} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>Huka Lodge</h3>
                                        <p>Set on 16 acres of countryside, Huka Lodge is located on the banks of the Waikato River. It features an outdoor pool, tennis court, spa pool and air-conditioned suites with a private terrace and stunning river views. Free airport transfers are available.</p>
                                        <div className="more">
                                            <a href="https://hukalodge.com/">Read More</a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text_box">

                                <div className="main_hotel">
                                    <a href="https://www.robertsonlodges.com/the-lodges/cape-kidnappers"
                                       target="_blank">
                                        <div className="img_box">
                                            <img src={ph15} alt=""/>
                                        </div>
                                    </a>
                                    <div className="text_hotel">
                                        <h3>The Farm at Cape Kidnappers</h3>
                                        <p>The luxurious 5-star accommodation options include private suites and a 4-room self-contained cottage. All are air-conditioned and feature a private balcony overlooking the ocean and the 18-hole golf course. All rooms have an LCD TV, DVD player and in-room safe.</p>
                                        <div className="more">
                                            <a href="https://www.robertsonlodges.com/the-lodges/cape-kidnappers">Read
                                                More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="text_box">

                                        <div className="main_hotel">
                                            <a href="https://eichardts.com/" target="_blank">
                                                <div className="img_box">
                                                    <img src={ph16} alt=""/>
                                                </div>
                                            </a>
                                            <div className="text_hotel">
                                                <h3>Eichardt's Private Hotel</h3>
                                                <p>Just a 5-minute walk from the waters of Lake Wakatipu, Eichardt's Private Hotel offers rooms with a fireplace, free Wi-Fi and satellite TV. It features a restaurant and bar. Some rooms have a balcony with mountain or lake views. All rooms have a sofa, dining area and tea/coffee making facilities.</p>
                                                <div className="more">
                                                    <a href="https://eichardts.com/">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text_box">

                                        <div className="main_hotel">
                                            <a href="#" target="_blank">
                                                <div className="img_box">
                                                    <img src={ph17} alt=""/>
                                                </div>
                                            </a>
                                            <div className="text_hotel">
                                                <h3>The George</h3>
                                                <p>Situated in the centre of beautiful Christchurch, overlooking Hagley Park and the River Avon, The George is a luxury 5-star boutique hotel. Guests can enjoy free Wi-Fi. A member of Small Luxury Hotels of the World, The George has 53 stylish and comfortable guest rooms. All rooms are spacious, feature contemporary furnishings and a modern bathroom</p>
                                                <div className="more">
                                                    <a href="https://www.thegeorge.com/">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </section>
        </div>

    )
}
export default Luxury
