
import Header from "./components/header/header";
import CardBest from "./components/Link_component/CardBest";

import Footer from "./components/footer/footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Luxury from "./components/TypeHotels/Luxury";
import Boutique from "./components/TypeHotels/Boutique";
import Casino from "./components/TypeHotels/Casino";
import Budget from "./components/TypeHotels/Budget";
import Contact from "./components/contact/Contact";
import Privacy from "./components/privacy/Privacy";
import Terms from "./components/terms/terms";

function App() {
  return (
      <div className="App">


          <BrowserRouter>
              <Header/>
              <Routes>
                  <Route path="/" index element={<CardBest />} />
                  <Route path="/luxury"  element={<Luxury />} />
                  <Route path="/boutique"  element={<Boutique />} />
                  <Route path="/casino"  element={<Casino />} />
                  <Route path="/budget"  element={<Budget />} />
                  <Route path="/contact"  element={<Contact />} />
                  <Route path="/privacy"  element={<Privacy />} />
                  <Route path="/terms"  element={<Terms />} />
              </Routes>
              <Footer/>
          </BrowserRouter>



      </div>
  );
}

export default App;
